import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { API_URL } from '../../../config'
import LayoutAccountEvent from '../LayoutAccountEvent'
import TechxplorationNavigation from './TechxplorationNavigation'
import { CalendarIcon, ChevronRightIcon, MagnifyingGlassCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { BreadcrumbsTechxploration } from '../Breadcrumbs'
import useLocalStorage from '../../Hooks/useLocalStorage'
import { sortByAttribute } from '../../../utils'
import EasyCrop from '../../EasyCrop'
import { dataURItoBlob } from '../../Crop'
import Loader from '../../Loader'

const JuryDemo = ({ juryDemo, setActiveTechxploration }) => {

    const [note, setNote] = useState(null)

    const getParcoursEval = async () => {

        await fetch(`${API_URL}?action=getParcoursEval&params= WHERE id_presta=${juryDemo.presta.id_presta} AND id_jury_event=${juryDemo.jury_event.id_jury_event}`)
            .then(res => res.json())
            .then(res => {
                setNote(res[0].presentation)
            })
    }

    const Rating = ({ note }) => {
        return <div className="star-rating py-4">

            <div className="text-gray-500 font-bold">Rating</div>

            <div className='flex'>

                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            type="button"
                            key={index}
                            className={` ${index <= note ? "text-yellow-500" : "text-gray-300"} bg-transparent border-none cursor-pointer`}

                        >
                            <span className="h-5 w-6  lg:h-10 lg:w-10 p-2 star lg:text-5xl text-5xl" >&#9733;</span>
                        </button>
                    );
                })}
            </div>
        </div>
    }

    const Media = ({ media = '', mediaName, mediaSource }) => {

        const [source, setSource] = useState(media);

        const [illustration, seIllustration] = useState(null);

        const [zoom, setZoom] = useState(false);

        useEffect(() => {

            if (media.includes("no_picture") || mediaSource == "https://www.mlg-consulting.com/manager_cc/docs/archives/") {
                return ''
            } else if (media.includes('.jpg') || media.includes('.png') || media.includes('.jpeg')) {
                seIllustration(<img src={mediaSource} alt="" className=" h-52  " />)
            } else if (media.includes('.mp4') || media.includes('.mov')) {
                seIllustration(<iframe iframe className="w-full aspect-video " src={media} ></iframe >)
            }

        }, [])

        return <div className=" w-full">
            <button onClick={() => setZoom(!zoom)} className="text-gray-500 flex mb-2 rounded-full bg-gray-100 mr-3 w-24">  <MagnifyingGlassCircleIcon className="h-8 pr-1" /> zoom</button>

            {zoom && <div className="fixed top-5 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="flex flex-col justify-center items-center bg-white rounded p-4">
                    <div className="flex justify-end w-full">
                        <button onClick={() => setZoom(!zoom)} className="text-gray-500"><XMarkIcon className='h-10' /></button>
                    </div>
                    <img onClick={() => setZoom(!zoom)} src={mediaSource} alt="" className="h-screen object-fill" />
                </div>
            </div>
            }

            <div onClick={() => setZoom(!zoom)} className="cursor-pointer rounded flex flex-col border border-slate-500  p-2 mr-2 justify-center  items-center text-xs text-gray-500">{illustration} {mediaName}</div>
        </div >
    }

    useEffect(() => {
        getParcoursEval()
    }, [note])

    return <div className="flex flex-col lg:flex-row py-2 border bg-white my-4 shadow-sm rounded p-2 lg:p-5">

        <div className='flex basis-1/3 pb-3 '>
            {!['https://www.mlg-consulting.com/manager_cc/docs/archives/', ''].includes(juryDemo.presta.video_hosted) && <Media media={juryDemo.presta.video_hosted} mediaName="video" />}

            {!['https://www.mlg-consulting.com/manager_cc/docs/archives/', 'https://www.mlg-consulting.com/manager_cc/docs/archives/no_picture.jpg', ''].includes(juryDemo.presta.presta_visuel) && <Media media={juryDemo.presta.presta_visuel} mediaName="visuel" mediaSource={juryDemo.presta.presta_visuel} />}
        </div>

        <div className="border-b basis-1/4 lg:hidden">

            <p className="font-bold">{juryDemo.presta.presta_nom}</p>
            <p>{juryDemo.presta.id_contact.societe}</p>
            <p className=" flex items-center text-sm text-gray-500">
                {/* {juryDemo.date_creation} */}
                Il y à
                { // if date = jour xxx heures
                    Math.floor((new Date() - new Date(juryDemo.date_creation)) / (1000 * 60 * 60)) % 24 > 0 &&
                    <span className="ml-2"> {Math.floor((new Date() - new Date(juryDemo.date_creation)) / (1000 * 60 * 60)) % 24} heures</span>

                }
            </p>


        </div>
        <div className="border-b basis-1/4 hidden lg:block">
            <b className="text-gray-500 font-bold">Solution :</b> {juryDemo.presta.presta_nom}
            <p><b className="text-gray-500 font-bold"> Descriptif :</b> {juryDemo.presta.presta_accroche}</p>
            <p><b className="text-gray-500 font-bold"> Société :</b> {juryDemo.presta.id_contact.societe}</p>
            <p><b className="text-gray-500 font-bold"> Stand :</b> {juryDemo.presta.numero_stand}</p>
            <p><b className="text-gray-500 font-bold"> Contact :</b> {juryDemo.presta.id_contact.prenom}  {juryDemo.presta.id_contact.nom} </p>
            <p><b className="text-gray-500 font-bold"> Coordonnées :</b> {juryDemo.presta.id_contact.port} / {juryDemo.presta.id_contact.mail}</p>

            <p>Id contact : {juryDemo.presta.id_contact.id_contact}</p>

            <p className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                Créée le : {juryDemo.date_creation}
            </p>

            <Rating note={note} />
        </div>

        <div className='flex flex-col justify-center'>
            <button className="bg-zinc-600 text-white rounded-full px-3 py-2" onClick={() => setActiveTechxploration({ presta: juryDemo.presta, contact: juryDemo.presta.id_contact })}>Editer la démo</button>
        </div>
    </div>
}

const JuryEvent = ({ juryEvent, setActiveTechxploration }) => {

    const [juryDemoList, setJuryDemoList] = useState(null)

    const [user] = useLocalStorage('user', null);

    const getJuryDemoList = async () => {

        await fetch(`${API_URL}?action=getJuryDemoList&params=WHERE id_jury=${juryEvent.id_jury}`)

            .then(res => res.json())
            .then((result) => {
                console.log(result)
                setJuryDemoList(result.filter(juryDemo => juryDemo.presta.id_contact.auteur.id_contact == user.id_contact))
            })
    }

    useEffect(() => {
        getJuryDemoList()
    }, [])


    if (!juryDemoList) return <div>Chargement...</div>

    return (
        <div className="w-screen">
            <div className='w-full'>
                {/* juryDemoList && juryDemoList.length */}

                {juryDemoList && juryDemoList.map(juryDemo => <JuryDemo
                    setActiveTechxploration={setActiveTechxploration}
                    key={juryDemo.id_jury_demo}
                    juryDemo={juryDemo} />)}
            </div>
        </div>
    )
}

const TechxplorationList = () => {

    const [juryEventList, setJuryEventList] = useState(null)

    const [activeTechxploration, setActiveTechxploration] = useState(null)

    const [image, setImage] = useState(null);
    const [srcFile, setSrcFile] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const [videoHostedFile, setVideoHostedFile] = useState(null);

    const handleFileChangeVideoHostedFile = (e) => {
        setVideoHostedFile(e.target.files[0]);
    };


    const handleImageUpload = async (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setSrcFile(e.target.files[0]);
    };

    const handleSaveFile = async ({ e, file_name, file }) => {

        const formData = new FormData();

        const visuel = document.getElementById(file_name).files[0];
        const name = document.getElementById(file_name).name;

        formData.append('id_presta', activeTechxploration.presta.id_presta);
        //formData.append(name, visuel);
        formData.append('file_name', file_name);

        formData.append(name, dataURItoBlob(imageData), visuel.name);

        await fetch(`${API_URL}?action=updatePresta`, {
            method: "POST",
            body: formData,
        }).then(() => {
            setCroppedImage(null);
            setImage(null);
        })
    }

    const { idEvent } = useParams();

    const [user] = useLocalStorage('user', null);



    const handleSubmitNewTechxploration = async (e) => {

        e.preventDefault()

        const formData = new FormData();

        const presta = {
            id_presta: activeTechxploration.presta.id_presta,
            presta_nom: activeTechxploration.presta.presta_nom,
            presta_accroche: activeTechxploration.presta.presta_accroche,
            numero_stand: activeTechxploration.presta.numero_stand,
        }

        const contact = {
            id_contact: activeTechxploration.contact.id_contact,
            prenom: activeTechxploration.contact.prenom,
            nom: activeTechxploration.contact.nom,
            societe: activeTechxploration.contact.societe,
            port: activeTechxploration.contact.port,
            mail: activeTechxploration.contact.mail
        }


        await fetch(`${API_URL}?action=updatePresta`, {
            method: 'POST',
            body: JSON.stringify(presta)
        })
            .then(res => res.json())
            .then((res) => {

                return fetch(`${API_URL}?action=updateContact`, {
                    method: 'POST',
                    body: JSON.stringify({ formData: contact })
                })
            }).then(res => setActiveTechxploration(null));


        //videoHostedFile
        const formDataUploadVideoHostedFile = new FormData();
        formDataUploadVideoHostedFile.append('id_presta', activeTechxploration.presta.id_presta);
        formDataUploadVideoHostedFile.append('video_hosted', videoHostedFile);
        formDataUploadVideoHostedFile.append('file_name', 'video_hosted');

        fetch(`${API_URL}?action=updatePresta`, {
            method: 'POST',
            body: formDataUploadVideoHostedFile
        })

    }

    const handleChangeActiveTechxploration = (e) => {

        let newActiveTechxploration;

        if (['prenom', 'nom', 'societe', 'port', 'mail'].includes(e.target.name)) {

            newActiveTechxploration = { ...activeTechxploration, contact: { ...activeTechxploration.contact, [e.target.name]: e.target.value } }

        } else if (['presta_nom', 'presta_accroche', 'numero_stand'].includes(e.target.name)) {

            newActiveTechxploration = { ...activeTechxploration, presta: { ...activeTechxploration.presta, [e.target.name]: e.target.value } }
        }

        setActiveTechxploration(newActiveTechxploration)
        console.log(newActiveTechxploration)
    }

    const getJuryEventList = async () => {

        // const params = `WHERE id_event=${idEvent}`;
        const params = ` WHERE id_jury_event IN ( SELECT id_jury_event FROM prestas p, contacts c,  jury_demo jd, jury_events je
 WHERE  p.id_contact = c.id_contact  AND jd.id_demo = p.id_presta AND  je.id_jury = jd.id_jury AND  c.auteur=${user.id_contact})`;


        await fetch(`${API_URL}?action=getJuryEventList&params=${params}`)

            .then(res => res.json())
            .then((result) => {

                const juryEventList = result.filter(juryEvent => juryEvent.conf_event.type.id_conf_event_type == "92");
                const juryEventListSorted = sortByAttribute(juryEventList, 'date_creation').reverse()

                setJuryEventList(juryEventListSorted)

                console.log(juryEventListSorted)
            })
    }

    useEffect(() => {
        getJuryEventList()
    }, [srcFile])




    return (
        <LayoutAccountEvent>
            <BreadcrumbsTechxploration />



            {activeTechxploration && <div className=" bg-white p-3 flex flex-col items-center border shadow">
                <button onClick={() => setActiveTechxploration(null)} className="mb-5 bg-gray-600 w-15 p-2 rounded-full text-white">X Fermer</button>

                <h2 className='text-lg font-medium text-gray-900'>Contact</h2>
                <div className="relative z-10 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 w-100">


                    <div className="">
                        <label htmlFor="prenom" className="block text-sm font-medium text-gray-700">
                            Prénom
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={handleChangeActiveTechxploration}
                                name="prenom"
                                id="prenom"
                                className="p-2  border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.contact.prenom} />
                        </div>
                    </div>

                    <div className="">
                        <label htmlFor="nom" className="block text-sm font-medium text-gray-700">
                            Nom
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={handleChangeActiveTechxploration}
                                name="nom"
                                id="nom"
                                className="p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.contact.nom} />
                        </div>
                    </div>

                    <div className="">
                        <label htmlFor="societe" className="block text-sm font-medium text-gray-700">
                            Raison sociale
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={handleChangeActiveTechxploration}
                                name="societe"
                                id="societe"
                                className="p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.contact.societe} />
                        </div>
                    </div>

                    <div className="">
                        <label htmlFor="port" className="block text-sm font-medium text-gray-700">
                            Mobile
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={handleChangeActiveTechxploration}
                                name="port"
                                id="port"
                                className="p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.contact.port} />
                        </div>
                    </div>

                    <div className="">
                        <label htmlFor="mail" className="block text-sm font-medium text-gray-700">
                            E-mail
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={handleChangeActiveTechxploration}
                                name="mail"
                                id="mail"
                                className="p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.contact.mail} />
                        </div>
                    </div>

                    <div className="">
                        <label htmlFor="numero_stand" className="block text-sm font-medium text-gray-700">
                            Numéro de stand
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={handleChangeActiveTechxploration}
                                name="numero_stand"
                                id="numero_stand"
                                className="p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.presta.numero_stand} />
                        </div>
                    </div>

                </div>

                <h2 className='text-lg font-medium text-gray-900 pt-3'>Solution</h2>



                <div className="relative z-10 mt-4 grid grid-cols-1 gap-y-6  w-100">







                    <div className="">
                        <div className="flex">
                            <div className="font-bold pr-5"> Visuel : </div>
                            <div>
                                <img src={croppedImage ?? activeTechxploration.presta.visuels.large} alt="Visuel" className="mx-5 w-20 border" />
                            </div>
                            <div>
                                <label className="_coverImage-holder_ text-white font-normal bg-gray-500 rounded-full p-2 px-3">
                                    Visuel
                                    <input
                                        id="presta_visuel"
                                        type="file"
                                        name="presta_visuel"
                                        onChange={handleImageUpload}
                                        style={{ display: "none" }}
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="font-bold pr-5"> Vidéo de présentation : </div>
                            <div>
                                {activeTechxploration.presta.video_hosted && <video src={activeTechxploration.presta.video_hosted} className="mx-5 w-20 border" controls></video>}
                            </div>
                            <div>
                                <label className="text-white font-normal bg-gray-500 rounded-full p-2 px-3">
                                    Vidéo
                                    <input
                                        className="hidden"
                                        id="video_hosted"
                                        type="file"
                                        name="video_hosted"
                                        onChange={handleFileChangeVideoHostedFile}
                                    />
                                </label>
                            </div>
                        </div>




                        {/*

{croppedImage && <img src={croppedImage} alt="cover" className="w-12" />}
*/}
                        <EasyCrop
                            croppedImage={croppedImage}
                            setCroppedImage={setCroppedImage}
                            image={image}
                            srcFile={srcFile}
                            setImageData={setImageData}
                        ></EasyCrop>

                        {image && <button onClick={(e) => handleSaveFile({ e: e, file_name: 'presta_visuel', file: image })}>sauvegarder</button>}
                    </div>









                    <div className="">
                        <label htmlFor="presta_nom" className="block text-sm font-medium text-gray-700">
                            Nom de la solution
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={handleChangeActiveTechxploration}
                                name="presta_nom"
                                id="presta_nom"
                                className="p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.presta.presta_nom} />
                        </div>
                    </div>

                    <div className="">
                        <label htmlFor="presta_accroche" className="block text-sm font-medium text-gray-700">
                            Description de la solution
                        </label>
                        <div className="mt-1">
                            <textarea
                                onChange={handleChangeActiveTechxploration}
                                name="presta_accroche"
                                id="presta_accroche"
                                className="p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                defaultValue={activeTechxploration.presta.presta_accroche} />
                        </div>
                    </div>
                </div>

                <button className="bg-blue-600 px-3 text-white mt-4 p-3 rounded" onClick={handleSubmitNewTechxploration}>Valider</button>
            </div>}


            {!juryEventList && <div className='animate animate-pulse flex flex-col justify-center'> <Loader /> Chargement des Tech'xplorations en cours...</div>}

            {
                !activeTechxploration && juryEventList && juryEventList.map(juryEvent => <JuryEvent
                    setActiveTechxploration={setActiveTechxploration}
                    key={juryEvent.id_jury_event}
                    juryEvent={juryEvent} />)
            }
        </LayoutAccountEvent >
    )
}

export default TechxplorationList