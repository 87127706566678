import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import RegistrationFormRow from './RegistrationFormRow'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { RegistrationFormSubtitleSection, RegistrationFormSupertitleSection } from './RegistrationFormStyleSection'
import HTMLReactParser from 'html-react-parser'

const RegistrationFormTechFundRaiserSection = ({
    formulaire,
    title, instructions, contact, setDisplayTechFundRaiserSection, displayTechFundRaiserSection
}) => {

    const [global_funding_traction, set_global_funding_traction] = useState(contact?.global_funding_traction);
    const [global_funding_product, set_global_funding_product] = useState(contact?.global_funding_product);
    const [global_funding_probleme_type, set_global_funding_probleme_type] = useState(contact?.global_funding_probleme_type);

    const [showBody, setShowBody] = useState(false);

    const { register, formState } = useFormContext();

    const { t, i18n } = useTranslation();

    //{t('form.sections.demo_section.solution_deja_presentee_description')} 

    const TractionList = [
        { label: t('form.sections.techfund_raiser.ca_recurrent_1m'), value: 1 },
        { label: t('form.sections.techfund_raiser.ca_recurrent_plus_100k'), value: 2 },
        { label: t('form.sections.techfund_raiser.ca_recurrent_moins_100k'), value: 3 },
        { label: t('form.sections.techfund_raiser.revenus_pilotes'), value: 4 },
        { label: t('form.sections.techfund_raiser.croissance_client'), value: 5 },
        { label: t('form.sections.techfund_raiser.pas_de_revenus'), value: 6 },
    ]
    const ProblemList = [
        { label: t('form.sections.techfund_raiser.probleme_serieux'), value: 1 },
        { label: t('form.sections.techfund_raiser.probleme_solvable'), value: 2 },
    ]

    const SolutionList = [
        { label: t('form.sections.techfund_raiser.produit_resolve_all'), value: 1 },
        { label: t('form.sections.techfund_raiser.produit_resolve_partiellement'), value: 2 },
        { label: t('form.sections.techfund_raiser.no_product'), value: 3 },
        { label: t('form.sections.techfund_raiser.taille_marche'), value: 4 },
        { label: t('form.sections.techfund_raiser.marche_en_croissance'), value: 5 },
    ]

    const DisplayList = ({ list, inputName, selectedOption, setSelectedOption }) => {

        return <fieldset className="space-y-2 mb-3 mt-2">

            {list.map((item, index) =>
                <>
                    <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                            <input
                                {...register(inputName)}
                                checked={selectedOption == item.value}
                                onChange={(e) => setSelectedOption(e.target.value)}
                                id={item.label}
                                value={item.value}
                                name={inputName}
                                type="radio"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor={item.label} className="font-medium text-gray-700" >{item.label}</label>
                        </div>
                    </div>
                </>)
            }
        </fieldset>
    }

    useEffect(() => {

        console.log(contact)

    }, [contact, i18n.resolvedLanguage])

    //    if (!contact) return (<div>Loading...</div>)
    return (
        <RegistrationFormSection

            formSectionId={20}
            supertitle={<RegistrationFormSupertitleSection>
                <div className="flex space-x-5 items-center">
                    <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/240726092121_cerlce-de-d-cideurs-r-cup-r-46.png" className="w-12 rounded" />
                    <div>{formulaire.recherche_investisseurs_section_supertitle}</div>
                </div>
            </RegistrationFormSupertitleSection>}
            subtitle_={<RegistrationFormSubtitleSection>{HTMLReactParser(t('form.sections.techfund_raiser.section_subtitle'))}</RegistrationFormSubtitleSection>}
        >

            <div className="alert alert-info my-3">

                <div className="flex items-center">
                    <img className="w-20 mr-5 basis-1/6" src="https://www.mlg-consulting.com/manager_cc/docs/archives/240703175137_whatsapp-image-2024-07-03-17.16.03-d7eab515.jpg" alt="" />

                    <div> {HTMLReactParser(formulaire.recherche_investisseurs_section_instructions != "" ? formulaire.recherche_investisseurs_section_instructions : t('form.sections.techfund_raiser.section_instructions'))}</div>
                </div>
            </div>


            <div className="flex space-x-3 my-4">
                <div className="font-bold">{t('form.sections.techfund_raiser.body_reveal_label')}</div>
                <div className="flex flex-col space-y-3">


                    <label className="font-normal ">
                        <input
                            name="global_funding_raiser_reveal"
                            type="radio"
                            onChange={() => setShowBody(!showBody)}
                            checked={!showBody} />  {t('form.sections.techfund_raiser.body_hide_input')}
                        {
                            " "
                            //t('form.sections.global_funding_connector.cherche_investisseurs')
                        }
                    </label>

                    <label className="font-normal ">
                        <input
                            name="global_funding_raiser_reveal"
                            type="radio"
                            onChange={() => setShowBody(!showBody)} />  {t('form.sections.techfund_raiser.body_reveal_input')}
                        {
                            " "
                            //t('form.sections.global_funding_connector.cherche_investisseurs')
                        }
                    </label>

                </div>
            </div>


            {
                /**  
                 * 
                 *  <p>Tour de financement : <input type="text" className="border rounded p-1" name="global_funding_tour_financement" defaultValue={contact?.global_funding_tour_financement} /></p>
                    <p>Montant d'argent collecté à ce jour :<input type="text" className="border  rounded p-1" name="global_funding_montant" defaultValue={contact?.global_funding_montant} /></p>
                    <p> Membres de l'équipe :<input type="text" className="border  rounded p-1" name="global_funding_membres_equipe" defaultValue={contact?.global_funding_membres_equipe} /></p>
                */
            }

            {showBody && <>

                {/* 
               <RegistrationFormSubtitleSection>{HTMLReactParser(t('form.sections.techfund_raiser.section_subtitle'))}</RegistrationFormSubtitleSection>
               */}

                <RegistrationFormRow
                    inputType="number"
                    name="fund_raising_taille_marche"
                    placeholder={t('form.sections.techfund_raiser.fund_raising_taille_marche')}
                    defaultValue={contact?.fund_raising_taille_marche}
                    required={false}
                    helper={t('global.en_millions_dollars')} />

                <RegistrationFormRow
                    inputType="number"
                    name="fund_raising_valorisation_entreprise"
                    placeholder={t('form.sections.techfund_raiser.fund_raising_valorisation_entreprise')}
                    defaultValue={contact?.fund_raising_valorisation_entreprise}
                    required={false}
                    helper={t('global.en_millions_dollars')} />

                <RegistrationFormRow
                    inputType="number"
                    name="global_funding_montant_capital_recherche"
                    placeholder={t('form.sections.techfund_raiser.montant_capital_recherche')}
                    required={false}
                    defaultValue={contact?.global_funding_montant_capital_recherche}
                    helper={t('global.en_millions_dollars')} />

                <RegistrationFormRow
                    inputType="number"
                    name="global_funding_tour_financement"
                    placeholder={t('form.sections.techfund_raiser.tour_financement')}
                    required={false}
                    defaultValue={contact?.global_funding_tour_financement} />
                <RegistrationFormRow
                    inputType="text"
                    name="global_funding_montant"
                    placeholder={t('form.sections.techfund_raiser.fonds_collectes')}
                    defaultValue={contact?.global_funding_montant}
                    required={false}
                    helper={t('global.en_millions_dollars')}
                />

                { /* 
            <RegistrationFormRow
                inputType="text"
                name="global_funding_membres_equipe"
                placeholder={t('form.sections.techfund_raiser.membres_equipe')}
                defaultValue={contact?.global_funding_membres_equipe}
                required={false} />
                */}



                <div className="font-bold">{t('form.sections.techfund_raiser.traction')}</div>
                <DisplayList list={TractionList}
                    inputName="global_funding_traction"
                    selectedOption={global_funding_traction}
                    setSelectedOption={set_global_funding_traction} />

                {/* <div className="font-bold">Problem</div>
                <DisplayList list={ProblemList} inputName="global_funding_product"
                    selectedOption={global_funding_product}
                    setSelectedOption={set_global_funding_product}
                />

                <div className="font-bold">{t('form.sections.techfund_raiser.product_solve')}</div>
                <DisplayList list={SolutionList} inputName="global_funding_probleme_type"
                    selectedOption={global_funding_probleme_type}
                    setSelectedOption={set_global_funding_probleme_type}
                /> */}



                <p className="font-bold text-xl py-3">
                    {t('form.sections.techfund_raiser.fund_raising_souhaite_accompagnement_levee_fond_title')}
                </p>

                <div className="flex space-x-5 w-full">
                    <div className="basis-2/12">
                        <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/250217140254_brique-pa-annuel-09.png" alt="" />
                    </div>


                    <div className="basis-10/2">
                        <RegistrationFormRow
                            inputType="radio"
                            name="fund_raising_souhaite_accompagnement_levee_fond"
                            placeholder={
                                t('form.sections.techfund_raiser.fund_raising_souhaite_accompagnement_levee_fond')}
                            defaultValue=""
                            required={false}
                            inputOptions={[{ label: t('global.yes'), value: 1 }, { label: t('global.no'), value: 0 }]} />

                        <RegistrationFormRow
                            inputType="file"
                            name="deck_presentation"
                            placeholder={t('form.sections.techfund_raiser.telecharger_dossier')}
                            defaultValue=""
                            required={false} />

                    </div>
                </div>



                <div className="bg-yellow-200 text-yellow-700 p-4 border-l-4 border-yellow-500 hidden">
                    {HTMLReactParser(t('form.sections.techfund_raiser.section_notabene'))}
                </div>
            </>}
        </RegistrationFormSection >
    )
}

export default RegistrationFormTechFundRaiserSection;