import React from 'react'

const Input = ({ register, label, name, type, value, handleChange, required, placeholder, errors }) => {
    return (
        <div>
            <label htmlFor={name} className="block font-bold text-gray-700">
                {label}
                {required && <span className="text-red-600">*</span>}
            </label>
            <input
                {...register(name, { required: true })}
                onChange={handleChange}
                className="p-3 mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                name={name}
                id={name}
                type={type}
                placeholder={placeholder}
                value={value || ''}
            />
            {errors[name] && <span className="text-red-600">This field is required</span>}
        </div>
    )
}

const UserlightProfile = ({
    contact,
    handleChange,
    paysList,
    register,
    errors
}) => {


    return (
        <div className="mt-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:gap-4 ">

                <Input register={register} label="E-mail" name="mail" type="mail" value={contact.mail} handleChange={handleChange} required={true} placeholder="" errors={errors} />
                <Input register={register} label="Mobile" name="port" type="tel" value={contact.port} handleChange={handleChange} required={true} placeholder="Mobile" errors={errors} />
                <Input register={register} label="First name" name="prenom" type="text" value={contact.prenom} handleChange={handleChange} required={true} placeholder="First name" errors={errors} />
                <Input register={register} label="Last name" name="nom" type="text" value={contact.nom} handleChange={handleChange} required={true} placeholder="Last name" errors={errors} />
                <Input register={register} label="Company" name="societe" type="text" value={contact.societe} handleChange={handleChange} required={true} placeholder="Company name" errors={errors} />
                <Input register={register} label="Website" name="web" type="text" value={contact.web} handleChange={handleChange} required={true} placeholder="Website" errors={errors} />

                {/* Country */}
                <div>
                    <label htmlFor="pays"
                        className="block font-bold text-gray-700">Country</label>
                    <select
                        name="pays"
                        className="p-3 mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={contact.pays || ''}
                        onChange={handleChange}
                    >
                        {paysList && paysList.map((pays, index) => (
                            <option key={index} value={pays.label}>
                                {pays.value}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="">
                    <label for="status"
                        className="block font-bold text-gray-700">
                        Define your status
                    </label>
                    <select id="id_statut" name="id_statut"
                        className="p-3 mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option value="">Define your status</option>
                        <option value="79">I am an investor and want to access to the investors deck of the startup</option>
                        <option value="77">I am a business developer or a tech retailor and could distribute the international expansion strategy of the showcase innovative solutions.</option>
                        <option value="80">I am a end user or a decision maker and could use or buy the solution showcased on stage</option><option value="82">I am a media or an influencor and would like to receive press release about the solution showcased</option><option value="75">I am a tech provider that could partner with the solution on stage please connect me</option><option value="84">I am territory or an accelerator and like to offer a welcome pack to my ecosystem to the showcased solution</option></select>
                </div>
            </div>
        </div>
    )
}

export default UserlightProfile;