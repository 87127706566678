
import React, { useState, useCallback } from 'react';
import { ArrowLeftIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { VideoCameraSlashIcon } from '@heroicons/react/24/solid';
import { VideoCameraIcon } from '@heroicons/react/24/solid';
import { FaYoutube } from 'react-icons/fa';


const PrestaIllustrationSection = ({ handleInputChange }) => {
    const [illustrationTypeSelected, setIllustrationTypeSelected] = useState(null);
    const [videoTypeSelected, setVideoTypeSelected] = useState(null);

    const handleSelectIllustrationType = useCallback((type) => (e) => {
        e.preventDefault();
        setIllustrationTypeSelected(type);
    }, []);

    const handleVideoTypeSelect = useCallback((type) => (e) => {
        e.preventDefault();
        setVideoTypeSelected(type);
    }, []);

    const handleBack = useCallback((e) => {
        e.preventDefault();
        setIllustrationTypeSelected(null);
        setVideoTypeSelected(null); // Reset video type as well
    }, []);

    return (
        <div className="flex flex-col space-y-3 pb-4 rounded-md ">
            {illustrationTypeSelected ? (
                <button
                    style={{ borderWidth: '2px' }}
                    onClick={handleBack} className="flex w-fit p-2 bg-neutral-50 rounded text-black shadow-none border-2  border-x border-y border-neutral-400 text-xs"><ArrowLeftIcon className="w-4" /> Retour</button>
            ) : (
                <div className="grid grid-cols-2 mb-3 gap-2">
                    <button
                        style={{ borderWidth: '2px' }}
                        className="text-sm  rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4"
                        onClick={handleSelectIllustrationType('video')}
                    >
                        <VideoCameraIcon className="w-10 h-10 " />

                        Je prends ou charge une vidéo
                    </button>
                    <button
                        style={{ borderWidth: '2px' }}
                        className="hidden group:hover:bg-pink-200 rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4"
                        onClick={handleSelectIllustrationType('image')}
                    >
                        <PhotoIcon className="h-10 w-10 text-neutral-600" />
                        Je prends ou charge une image
                    </button>
                    <label className="text-sm rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4">

                        <PhotoIcon className="h-10 w-10 text-neutral-400" /> Je prends ou charge une image
                        <input
                            className="hidden mt-3 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            type="file"
                            name="file_src"
                            onChange={handleInputChange}
                            accept="image/png, image/jpeg"
                        />
                    </label>






                </div>
            )}

            {illustrationTypeSelected === 'image' && (
                <div>
                    <label className="flex flex-col">
                        <span className="text-gray-700 font-normal text-sm">Chargez votre image d'illustration (PNG ou JPG uniqumement)  :</span>
                        <input
                            className="mt-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            type="file"
                            name="file_src"
                            onChange={handleInputChange}
                            accept="image/png, image/jpeg"
                        />
                    </label>
                </div>
            )}

            {illustrationTypeSelected === 'video' && (
                <div>
                    <div className="flex space-x-2 mb-3">
                        <label className=" w-1/2 text-sm rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4"   >
                            <VideoCameraIcon className="h-10 w-10" />
                            Je prends ou charge une vidéo
                            <input
                                className="hidden mt-1  w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="file"
                                name="file_src"
                                onChange={handleInputChange}
                                accept="video/mp4"
                            />
                        </label>
                        <button
                            className={`p-4 w-1/2 flex flex-col items-center  hover:bg-neutral-200  rounded-lg  px-2 font-normal text-sm text-black  border-x border-y  border-neutral-200 ${videoTypeSelected === 'link' ? 'bg-neutral-300 ' : 'bg-neutral-50 '}`}
                            onClick={handleVideoTypeSelect('link')}
                        >

                            <FaYoutube className="h-10 w-10 text-neutral-400" />




                            Je copie/colle un lien youtube
                        </button>
                        <button
                            className={`hidden hover:bg-neutral-200 rounded-lg   px-2 font-normal text-sm text-black border-x border-y  border-neutral-200  ${videoTypeSelected === 'upload' ? 'bg-neutral-300 ' : 'bg-neutral-50 '}`}
                            onClick={handleVideoTypeSelect('upload')}
                        >
                            Je prends une vidéo ou la télécharge au format mp4
                        </button>

                    </div>

                    {videoTypeSelected === 'upload' && (
                        <label className="block">
                            <span className="text-gray-700 font-bold">Chargement de la vidéo :</span>
                            <input
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="file"
                                name="file_src"
                                onChange={handleInputChange}
                                accept="video/mp4"
                            />
                        </label>
                    )}

                    {videoTypeSelected === 'link' && (
                        <label className="block">
                            <span className="text-gray-700 font-bold">Lien youtube :</span>
                            <input
                                className="p-2 border mt-1 block w-full rounded-full border-gray-700 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                name="contribution"
                                placeholder="e.g., URL"
                                onChange={handleInputChange}
                            />
                        </label>
                    )}
                </div>
            )}
        </div>
    );
};


export default PrestaIllustrationSection;