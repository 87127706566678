import { ChevronRightIcon, HomeIcon, ListBulletIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import eventContext from '../../contexts/EventContext'
import EventContext from '../../contexts/EventContext'



const Item = ({ item }) => {

    return <div className="flex items-center">
        <svg
            className="h-full w-6 flex-shrink-0 text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
        >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
        <a
            href={item?.href}
            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 flex items-center"
            aria-current={item?.current ? 'page' : undefined}
        >
            {item?.logoUrl ? <img src={item?.logoUrl} className="h-6 " /> : item?.name}
        </a>
    </div>
}

const Breadcrumbs = (props, page = '') => {

    const { idEvent } = useParams();
    const { getEventByIdEvent, currentEvent } = useContext(EventContext);

    useEffect(() => {
        getEventByIdEvent(idEvent)
    }, [])

    if (currentEvent === null) return null
    return (

        <div className="flex px-2   items-center    py-2   border align-middle w-fit">
            <Link to={`/account/event/${idEvent}`}>
                <HomeIcon className="h-8  flex  flex-shrink-0 mr-2 " aria-hidden="true" />
            </Link>
            <ChevronRightIcon className="d-none h-8  ml-2 mr-5 text-gray-300" />
            {props.children}
        </div>

    )
}

export const BreadcrumbsProgramme = () => {

    const { idEvent } = useParams();

    return (
        <>
            <Breadcrumbs>
                <Item item={{ name: "Mon programme  " }} />

            </Breadcrumbs>
            <div className='flex justify-end'>
                <Link className="mr-5 border rounded-2xl p-2 flex justify-center items-center md:flex-row" to={`/account/event/${idEvent}/schedule-selection/`} ><PlusCircleIcon className="text-2xl w-8 text-blue-600" /> Ajouter / Modifier </Link>
                <Link className="mr-5 border rounded-2xl p-2 flex justify-center items-center md:flex-row" to={`/account/event/${idEvent}/schedule/`}><ListBulletIcon className="text-2xl w-8 text-blue-600" />Mon programme</Link>
            </div>
        </>
    )
}
export const BreadcrumbsParcours = () => {


    return (
        <Breadcrumbs>
            <div className='flex text-gray-500'> Mes parcours  de visites </div>
        </Breadcrumbs>
    )
}


export const BreadcrumbsPitch = () => {


    return (
        <>        <Breadcrumbs>
            <div className='flex text-gray-500'> Mes sessions de pitch </div>
        </Breadcrumbs>

        </>

    )
}

export const BreadcrumbsTechxploration = () => {
    const { idEvent } = useParams();
    return (
        <div className="flex  justify-between items-center space-x-1">
            <Breadcrumbs>
                <Item item={{ href: `/account/event/${idEvent}/techxploration`, name: "Tech'xploration", logoUrl: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250310123321_brique-picto-38-1-.png' }} />
            </Breadcrumbs>
            <div className='flex justify-end space-x-1 '>
                <Link className=" text-xs  border rounded-2xl p-2 flex flex-col justify-center items-center md:flex-row" to={`/account/event/${idEvent}/techxploration`} ><PlusCircleIcon className="text-2xl w-8 text-blue-600" /> Nouveau </Link>
                <Link className="text-xs  border rounded-2xl p-2 flex flex-col justify-center items-center md:flex-row" to={`/account/event/${idEvent}/techxploration/my-techxplorations`}><ListBulletIcon className="text-2xl w-8 text-blue-600" />Liste</Link>
            </div>
        </div>
    )
}


export default Breadcrumbs