import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import HTMLReactParser from 'html-react-parser';

import useGetCycleLang from '../customHooks/useGetCycleLang';
import useGetEventContactTypeList from '../customHooks/useGetEventContactTypeList';
import { API_URL } from '../config';
import useGetContactCycleLang from '../customHooks/useGetContactCycleLang';
import Loader, { CircleLoader } from './Loader';
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/24/solid';


const CollectifGeographiqueListItem = ({ cycleLang, contactCycleLangList, handleSelectCycleLang,
    activeEventContactType,
    contactCycleLangListisLoading }) => {

    return <>
        <div>

            <label className="font-normal   text-sm flex space-x-2 items-center" key={cycleLang.id_cycle_lang}>

                {contactCycleLangListisLoading && <CircleLoader />}

                {!contactCycleLangListisLoading && <input
                    type="checkbox"
                    value={cycleLang.id_cycle_lang}
                    onChange={handleSelectCycleLang}
                    checked={contactCycleLangList && contactCycleLangList
                        .filter(contactCycleLang =>
                            activeEventContactType && activeEventContactType.id_event_contact_type === contactCycleLang.id_event_contact_type &&
                            contactCycleLang.id_cycle_lang === cycleLang.id_cycle_lang).length > 0}

                />} <span>{HTMLReactParser(cycleLang.nom_cycle_lang)}</span>

            </label>
        </div>

    </>
}

const CollectifGeographiqueList = ({
    cycleLangList,
    contactCycleLangListisLoading,
    handleSelectCycleLang,
    contactCycleLangList,
    activeEventContactType,
    parent }) => {


    return (
        <div>

            {cycleLangList && cycleLangList
                .filter((cycleLang) => cycleLang.cycle_lang_state = "active")
                .map((cycleLang, index) =>
                    <CollectifGeographiqueListItem
                        key={cycleLang.id_cycle_lang}
                        cycleLang={cycleLang}
                        contactCycleLangList={contactCycleLangList}
                        handleSelectCycleLang={handleSelectCycleLang}
                        activeEventContactType={activeEventContactType}
                        contactCycleLangListisLoading={contactCycleLangListisLoading}
                    />
                )}

        </div>
    )
}


const DialogPays = ({ dialogIsvisible, setDialogIsvisible, cycleLangList,
    contactCycleLangList, activeEventContactType, handleSelectCycleLang, contactCycleLangListisLoading }) => {

    return (
        <dialog open={dialogIsvisible} className="fixed inset-0 z-50 overflow-y-auto bg-white bg-opacity-50">
            <div className={` flex items-center w-full  justify-center ${dialogIsvisible ? 'block' : 'hidden'}`}>
                <div className="bg-white rounded-lg shadow-lg p-6  w-full z-50">

                    <div className="flex justify-between items-center mb-5">
                        <h2 className="text-2xl font-bold">{activeEventContactType?.libelle}</h2>
                        <button
                            className="w-10 h-10 top-0 right-0 mt-4 mr-4 text-gray-400 rounded-full hover:text-gray-600"
                            onClick={(e) => {
                                e.preventDefault();
                                setDialogIsvisible(false);
                            }}
                        >
                            &times;
                        </button>
                    </div>


                    {contactCycleLangListisLoading && <div className="animate-pulse my-5 p-3 bg-emerald-200 rounded text-green-900">Traitement en cours veuillez patienter</div>}
                    <div className="grid grid-cols-5">

                        {cycleLangList && cycleLangList
                            .filter((cycleLang) => cycleLang.cycle_lang_state = "active" && cycleLang.cycle_lang_categorie === "continent")
                            .map((cycleLang, index) => <div className="border border-gray-200 p-2 m-2 rounded" key={cycleLang.id_cycle_lang}>



                                <span className="font-bold ">{HTMLReactParser(cycleLang.nom_cycle_lang)}</span>
                                <label className="font-normal text-sm  space-x-2 items-center hidden flex" >

                                    {contactCycleLangListisLoading && <CircleLoader />}

                                    {!contactCycleLangListisLoading && <input
                                        type="checkbox"
                                        value={cycleLang.id_cycle_lang}
                                        onChange={handleSelectCycleLang}
                                        checked={contactCycleLangList && contactCycleLangList
                                            .filter(contactCycleLang =>
                                                activeEventContactType && activeEventContactType.id_event_contact_type === contactCycleLang.id_event_contact_type &&
                                                contactCycleLang.id_cycle_lang === cycleLang.id_cycle_lang).length > 0}

                                    />} <span className="font-bold">{HTMLReactParser(cycleLang.nom_cycle_lang)}</span>

                                </label>

                                {cycleLang.children && cycleLang.children

                                    //trier les pays par ordre alphabétique
                                    .sort((a, b) => a.nom_cycle_lang.localeCompare(b.nom_cycle_lang))
                                    .map((cycleLang, index) =>
                                        <CollectifGeographiqueListItem
                                            cycleLang={cycleLang}
                                            contactCycleLangList={contactCycleLangList}
                                            handleSelectCycleLang={handleSelectCycleLang}
                                            activeEventContactType={activeEventContactType}
                                            contactCycleLangListisLoading={contactCycleLangListisLoading}
                                            key={cycleLang.id_cycle_lang}


                                        />)}




                                {/* <CollectifGeographiqueList
                                    contactCycleLangList={contactCycleLangList}
                                    cycleLangList={cycleLangList}
                                    contactCycleLangListisLoading={contactCycleLangListisLoading}
                                    handleSelectCycleLang={handleSelectCycleLang}
                                    activeEventContactType={activeEventContactType}
                                    parent={cycleLang.id_cycle_lang}

                                /> */}
                            </div>
                            )}


                    </div>

                    <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/250226104329_firefox-screenshot-2025-02-26t09-41-02.461z.png" alt="Dialog Content" className="hidden w-full h-auto" />
                </div>
                <div className="fixed inset-1 bg-black opacity-50 " onClick={() => setDialogIsvisible(false)}></div>
            </div>
        </dialog>
    )
}

const SelectedContactCycleLangListItem = ({ contactCycleLang, deleteContactCycleLang }) => {

    if (!contactCycleLang) return null;

    return <div className="flex text-xs border border-gray-200 p-1 m-1 rounded items-center space-x-2 justify-between">
        <img src={contactCycleLang.cycle_lang.logo_better.tiny} alt={contactCycleLang.cycle_lang.nom_cycle_lang} className="w-6" />
        <span>{contactCycleLang.cycle_lang.nom_cycle_lang}</span>
        <button
            className="flex justify-center w-8 h-8 bg-white  hover:text-gray-700"
            onClick={(e) => {
                e.preventDefault();
                deleteContactCycleLang({ id_contact_cycle_lang: contactCycleLang.id_contact_cycle_lang })
            }}
        >
            <TrashIcon className="w-4 h-4 text-black " /></button>
    </div>

}

const RegistrationFormProviderSection = ({ title, instructions, supertitle, contact, filter, formSectionId }) => {

    const [dialogIsvisible, setDialogIsvisible] = useState(false);

    const { eventContactTypeList } = useGetEventContactTypeList({ filter: filter });

    const { getContactCycleLang, contactCycleLangList, isLoading } = useGetContactCycleLang({ filter: `id_contact=${contact?.id_contact}` });

    const { cycleLangList } = useGetCycleLang({ filter: 'cycle_lang_type="collectif_geographique" AND cycle_lang_categorie IN("pays", "continent") AND &get_children=1' });

    const [activeEventContactType, setActiveEventContactType] = useState(null);

    const createContactCycleLang = async ({ id_contact, id_cycle_lang, id_event_contact_type }) => {

        await fetch(`${API_URL}?action=createContactCycleLang`,
            {
                method: 'POST',
                body: JSON.stringify({
                    id_event_contact_type: id_event_contact_type,
                    id_contact: id_contact,
                    id_cycle_lang: id_cycle_lang
                })
            }
        )
            .then(res => res.json())
            .then(res => {
                getContactCycleLang({ filter: `id_contact=${contact.id_contact}` });
            })
    }


    const deleteContactCycleLang = async ({ id_contact_cycle_lang }) => {

        await fetch(`${API_URL}?action=deleteContactCycleLang&id_contact_cycle_lang=${id_contact_cycle_lang}`)
            .then(res => res.json())
            .then(res => {
                getContactCycleLang({ filter: `id_contact=${contact.id_contact}` });
            })
    }

    const handleSelectCycleLang = (e) => {

        const isChecked = e.target.checked;

        if (isChecked) {

            createContactCycleLang({
                id_contact: contact.id_contact,
                id_cycle_lang: e.target.value,
                id_event_contact_type: [activeEventContactType.id_event_contact_type]
            })

        } else {

            deleteContactCycleLang({
                id_contact_cycle_lang: contactCycleLangList
                    .filter(contactCycleLang =>
                        contactCycleLang.id_event_contact_type === activeEventContactType.id_event_contact_type &&
                        contactCycleLang.id_cycle_lang === e.target.value)[0].id_contact_cycle_lang
            })
        }

    }

    const handleSelectService = (e) => {

        const isChecked = e.target.checked;

        if (isChecked) {
            setDialogIsvisible(true);
        } else {
            setDialogIsvisible(false);
        }
    }

    useEffect(() => {
        getContactCycleLang({ filter: `id_contact=${contact?.id_contact}` });
    }
        , [contact])

    return (
        <RegistrationFormSection
            title={title}
            formSectionId={formSectionId}
            instructions={instructions}>


            <table>
                <tbody className="divide-y divide-gray-200">

                    {Array.isArray(cycleLangList) && eventContactTypeList && eventContactTypeList
                        .map((eventContactType, index) => (

                            <tr className="divide-x divide-gray-200" key={index}>
                                <td className="px-2">
                                    <label className="font-normal   flex items-center justify-between" key={index}>

                                        <span className="flex items-center space-x-2">
                                            {contactCycleLangList && contactCycleLangList
                                                .filter(contactCycleLang => contactCycleLang.id_event_contact_type === eventContactType.id_event_contact_type).length > 0
                                                && <CheckCircleIcon className="w-8 h-8 text-green-500" />
                                            }

                                            <input
                                                className="hidden"
                                                type="checkbox"
                                                value={eventContactType.id_event_contact_type}
                                                onChange={(e) => {
                                                    setActiveEventContactType(eventContactType);
                                                    handleSelectService(e);
                                                }}
                                            /> {HTMLReactParser(eventContactType.libelle ?? "")}

                                        </span>
                                        <span
                                            onChange={(e) => {
                                                setActiveEventContactType(eventContactType);
                                                handleSelectService(e);
                                            }}
                                            className="text-blue-700 mx-3 cursor-pointer hover:underline w-20 bg-white shadow-none">

                                            {contactCycleLangList && contactCycleLangList
                                                .filter(contactCycleLang => contactCycleLang.id_event_contact_type === eventContactType.id_event_contact_type).length > 0 ? <div> éditer</div> : <div>Sélectionner et préciser les pays</div>}

                                        </span>
                                    </label>
                                </td>
                                <td>

                                    {isLoading &&
                                        <CircleLoader />
                                    }

                                    <div className="flex flex-wrap">
                                        {contactCycleLangList && contactCycleLangList
                                            .filter(contactCycleLang => contactCycleLang.id_event_contact_type === eventContactType.id_event_contact_type)
                                            .map(contactCycleLang =>
                                                <SelectedContactCycleLangListItem
                                                    deleteContactCycleLang={deleteContactCycleLang}
                                                    key={contactCycleLang.id_contact_cycle_lang}
                                                    contactCycleLang={contactCycleLang} />)}
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            <DialogPays
                contactCycleLangListisLoading={isLoading}
                contactCycleLangList={contactCycleLangList}
                handleSelectCycleLang={handleSelectCycleLang}
                activeEventContactType={activeEventContactType}
                dialogIsvisible={dialogIsvisible}
                setDialogIsvisible={setDialogIsvisible}
                cycleLangList={cycleLangList} />

        </RegistrationFormSection>
    )
}

export default RegistrationFormProviderSection