import React, { useState } from 'react'

const StarRating = ({ object, attributeName, setFunction }) => {

    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const handleClick = (index) => {
        setRating(index)
        setFunction({ ...object, [attributeName]: index })
        console.log({ ...object, [attributeName]: index })
    }

    return (
        <div className="star-rating  flex justify-items-center justify-center">

            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <button
                        type="button"
                        key={index}
                        className={`   ${index <= (hover || rating) ? "text-yellow-500" : "text-gray-300"} bg-transparent border-none cursor-pointer`}
                        onClick={() => {
                            //setRating(index); setFunction({ ...object, [attributeName]: index })
                            handleClick(index)
                        }}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        <span className="h-10 w-10 p-2 star text-5xl" >&#9733;</span>
                    </button>
                );
            })}
        </div>
    );
};
export default StarRating
