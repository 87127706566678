import React, { useEffect, useState } from 'react'

import useApiUrlTranslation from './useApiUrlTranslation';
import RegistrationFormSection from './RegistrationFormSection';
import { useFormContext } from 'react-hook-form';
import DisplayEventContactType from './DisplayEventContactType';
const RegistrationFormRoleMultipleSection = ({ title, instructions }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();
    const [eventContactTypeList, setEventContactTypeList] = useState();

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=WHERE%20event_contact_type_is_active%20=1`)

            .then(response => response.json())
            .then(data => {
                setEventContactTypeList(data);
            })
    }

    useEffect(() => {
        getEventContactTypeList();
    }, [i18n.resolvedLanguage])


    if (!eventContactTypeList) return null
    return (
        <RegistrationFormSection
            title={title}
            instructions={instructions}
            formSectionId={28}
            instructions_="Merci de cocher les cases ci-dessous qui correspondent à vos choix :"
            title_="Détail de vos engagements et contributions pour myGlobalVillage">

            <DisplayEventContactType
                eventContactTypeList={eventContactTypeList.filter(eventContactType => ["92", "81", "58", "208", "74"].includes(eventContactType.id_event_contact_type))}
                title={t('form.sections.role_multiple_section.contribution_non_membres_title')}
                instructions={t('form.sections.role_multiple_section.contribution_non_membres_instructions')} />

            <DisplayEventContactType
                title={t('form.sections.role_multiple_section.contribution_membres_title')}
                instructions={t('form.sections.role_multiple_section.contribution_membres_instructions')}
                eventContactTypeList={eventContactTypeList.filter(eventContactType => ["238", "203", "45", "217", "213", "209", "234", "252", "146", "222", "207"].includes(eventContactType.id_event_contact_type))} />

            <DisplayEventContactType
                title={t('form.sections.role_multiple_section.contribution_buisness_developper')}
                instructions={t('form.sections.role_multiple_section.contribution_buisness_developper_instructions')}
                eventContactTypeList={eventContactTypeList.filter(eventContactType => ["54", "177"].includes(eventContactType.id_event_contact_type))} />

        </RegistrationFormSection>
    )
}

export default RegistrationFormRoleMultipleSection