import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LayoutAccount from './LayoutAccount'
import { useParams } from 'react-router-dom'
import EventContext from '../../contexts/EventContext'
import { HomeIcon } from '@heroicons/react/20/solid'

const LayoutAccountEvent = (props) => {

    const { idEvent } = useParams();

    const { getEventByIdEvent, currentEvent } = useContext(EventContext);



    useEffect(() => {
        getEventByIdEvent(idEvent)
    }, [])

    if (!currentEvent) {
        return false
    }

    return (
        <LayoutAccount {...props} >
            <Link to={`/account/event/${idEvent}`}
                className="bg-gray-800 py-2 text-gray-300 items-center  
                flex space-x-4 rounded-md  px-1  align-middle mb-5">

                {currentEvent.id_event == 1927 && <>
                    <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/250310175239_sans-titre-1-01.png" alt=""
                        className="w-10 rounded mr-2 " />
                    <img src="https://www.mlg-consulting.com/manager_cc/docs/archives//thumbs/250304151130_brique-picto-21_1000x0.png" alt=""
                        className="w-20 rounded mr-2 " />
                </>
                }


                {currentEvent.id_event != 1927 && <>
                    <img src={currentEvent?.event_ligne.el_visuels.small} alt=""
                        className="w-10 rounded mr-2 " />
                    <p className=" text-xl font-bold  text-white sm:truncate sm:text-xl sm:tracking-tight"> {currentEvent?.nom}</p>
                </>
                }
            </Link>
            {/*
           <Link to={`../${idEvent}`} className="flex space-x-4 rounded-md bg-white px-6 text-gray-500 p-2 mb-3 ">

                <HomeIcon className="h-5 w-5 flex-shrink-0 mr-2" aria-hidden="true" /> {currentEvent?.nom}
            </Link>
             */}
            {props.children}
        </LayoutAccount>
    )
}

export default LayoutAccountEvent