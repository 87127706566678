import React, { useContext, useEffect, useState } from 'react'
import LayoutAccount from './LayoutAccount2.js'
import UserContext from '../../contexts/UserContext';
import EventContext from '../../contexts/EventContext'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { API_URL } from '../../config';
import Moment from 'react-moment';
import useGetConfEvent from '../../customHooks/useGetConfEvent';
import LayoutAccountEvent from './LayoutAccountEvent';
import TimezoneSelectSection from '../TimezoneSelectSection.js';
import HTMLReactParser from 'html-react-parser';
import { set } from 'react-hook-form';
import Loader from '../Loader.js';

const eventContactTypeList = [
    { id_event_contact_type: 259, name: "Je souhaite assister à cette session" }
]

const confEventTypeList = [
    {
        id_conf_event_type: 78, name: "Debriefing (FR)",
        eventContactTypeList: eventContactTypeList
    },
    {
        id_conf_event_type: 95, name: "Tech'xploration Briefing",
        eventContactTypeList: eventContactTypeList
    },
]


const ConfEventItem = ({ confEvent, handleChange, contactConferencierList, updateIsLoading, contactConferencierListIsLoading }) => {

    const timeZone = <div className="flex items-center divide-x space-x-2 bg-neutral-100 p-2 w-fit rounded my-2 ">
        <div className='font-normal p-1 rounded mt-2 cursor-pointer'>
            <div className='  text-neutral-500 text-sm'>{HTMLReactParser(confEvent.localTimeZone)}</div>
            <div className='flex flex-wrap text-xs text-neutral-500 mt-2 d-none'>{HTMLReactParser(confEvent.otherTimeZones)}</div>
        </div>

    </div>
    const horaires = confEvent.defaultTimeZones ? timeZone : <span style={{ fontWeight: "bold", fontSize: "13px" }}>{confEvent.heure_debut} - {confEvent.heure_fin} <br /> </span>


    return (

        <div
            className="border-b sm:border-none flex flex-col flex-wrap md:flex-row hover:bg-gray-100 divide-x-2 mb-6 sm:mb-0 md:mb-0 pt-2"
            id_conf_event="176743"
        >
            <div className="basis-1/12 flex items-center px-2 text-white bg-neutral-800 justify-center">
                <div className="text-xs text-center">
                    <p className="text-xs p-2 hidden">Local time</p>
                    {confEvent.heure_debut_local} - {confEvent.heure_debut_local}
                </div>
            </div>
            <div className="basis-9/12">
                <label htmlFor="5776" className="font-normal w-full  ">
                    <div>
                        <div className="flex items-center hidden">
                            <span
                                className="break-words mr-3 text-white rounded-full px-3 py-2 text-xs font-normal"
                                style={{ backgroundColor: "rgb(29, 78, 216)" }}
                            >
                                Tech'Xport&nbsp;hub
                            </span>
                            <div>
                                <div
                                    style={{ backgroundColor: "rgb(29, 78, 216)" }}
                                    className="flex items-center space-x-4 pr-4 rounded-full opacity-70"
                                >
                                    <img
                                        src="https://www.mlg-consulting.com/manager_cc/docs/archives/241029124030_brique-picto-33.png"
                                        alt=""
                                        className="rounded-s-full h-8 invert"
                                        style={{ filter: "invert(100%)" }}
                                    />
                                    <span className="text-white font-normal text-xs hidden">
                                        X'Port hub
                                    </span>
                                </div>
                            </div>
                            <div className="flex ml-5">
                                <div>
                                    <img
                                        src="https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/250225164945_logo-vecto-fr-2023-r-cup-r-85_200x0.png"
                                        alt=""
                                        className="w-10"
                                    />
                                </div>
                                <div>
                                    <img
                                        src="https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/231009165622_logo-vecto-2023-13_200x0.png"
                                        alt=""
                                        className="w-10"
                                    />
                                </div>
                                <div>
                                    <img
                                        src="https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/250225171844_logo-vecto-fr-2023-r-cup-r-80_200x0.png"
                                        alt=""
                                        className="w-10"
                                    />
                                </div>
                            </div>
                        </div>
                        <span className="font-bold">

                            {confEvent.conf_event_lang.cel_titre}
                        </span>
                    </div>
                    <div className="hidden flex items-center divide-x space-x-2 bg-neutral-100 p-2 w-fit rounded my-2">

                    </div>

                    {/* {horaires} */}
                </label>
            </div>
            <div className="basis-2/12 px-1">
                <div className="flex flex-col  flex-wrap divide-y space-y-2">

                    {confEventTypeList
                        .filter(confEventType => confEventType.id_conf_event_type == confEvent.type)
                        .map((confEventType, index) => {

                            return <div className="flex flex-col  flex-wrap divide-y space-y-2">
                                {confEventType.eventContactTypeList
                                    .map((eventContactType, index) => {
                                        return <label htmlFor="" className="text-xs">

                                            {contactConferencierListIsLoading && <Loader />}
                                            {!contactConferencierListIsLoading && <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                checked={contactConferencierList && contactConferencierList
                                                    .filter(item => item.conf_event.id_conf_event == confEvent.id_conf_event)   //filter by conf_event
                                                    .filter(item => item.statut.id_event_contact_type == eventContactType.id_event_contact_type) //filter by event_contact_type 
                                                    .length > 0}
                                                id_conf_event={confEvent.id_conf_event}
                                                id_event_contact_type={eventContactType.id_event_contact_type}
                                                onChange={handleChange}
                                            />} {eventContactType.name} </label>
                                    })}
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const MyAgenda = () => {

    const { idEvent } = useParams();

    const userContext = useContext(UserContext);

    //useGetConfEvent
    const { confEventList, isLoading } = useGetConfEvent({ idEvent, filter: '' })

    const { getEventByIdEvent, currentEvent } = useContext(EventContext);

    const [contactConferencierList, setContactConferencierList] = useState(null);

    const [contactConferencierListIsLoading, setContactConferencierListIsLoading] = useState(false)

    const [updateIsLoading, setUpdateIsLoading] = useState(false)


    const createConferencier = async ({ id_conf_event, id_event_contact_type }) => {

        setUpdateIsLoading(true)

        let data = {
            id_conf_event: id_conf_event,
            id_contact: userContext.user.id_contact,
            statut: id_event_contact_type,
            id_event: idEvent
        }

        await fetch(`${API_URL}?action=createConferencier`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                setUpdateIsLoading(false)
                getContactConferencierList(userContext.user.id_contact)
                console.log(res)
            })

    }

    const deleteConferencier = async ({ id_conf_event, id_event_contact_type }) => {

        const id_conferencier = contactConferencierList
            .filter(item => item.conf_event.id_conf_event == id_conf_event)
            .filter(item => item.statut.id_event_contact_type == id_event_contact_type)
            .map(item => item.id_conferencier)[0]

        await fetch(`${API_URL}?action=deleteConferencier`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_conferencier: id_conferencier,
            }),
        })
            .then(res => res.json())
            .then(res => {
                getContactConferencierList(userContext.user.id_contact)
                console.log(res)
            })
    }



    const handleChange = (e) => {

        const id_conf_event = e.target.getAttribute('id_conf_event')
        const id_event_contact_type = e.target.getAttribute('id_event_contact_type')

        if (e.target.checked) {
            createConferencier({ id_conf_event, id_event_contact_type })
        } else {
            deleteConferencier({ id_conf_event, id_event_contact_type })
        }
    }

    const getContactConferencierList = async (id_contact) => {

        setContactConferencierListIsLoading(true)

        await fetch(`${API_URL}?action=getContactConferencierList&filter= AND c.id_contact=${id_contact} AND DATE(cf.date_creation)>2018`)
            .then(res => res.json())
            .then(res => {

                setContactConferencierList(res);
                setContactConferencierListIsLoading(false)
            })
    }

    useEffect(() => {
        getContactConferencierList(userContext.user.id_contact)
    }
        , [])

    return (
        <LayoutAccountEvent>
            Je m'inscris aux sessions de tech'Xploration de mon choix ci-dessous

            {/* {JSON.stringify(confEventList)} */}


            <div className="divide-y divide-gray-200 mt-5 w-full">

                {/* {contactConferencierList && contactConferencierList
                    .filter(item => item.event.id_event == idEvent)
                    .filter(item => ["234", "259"].includes(item.statut.id_event_contact_type))
                    .filter(item => item.conf_event.id_conf_event != null)
                    .sort((a, b) => new Date(a.conf_event.conf_event_date_local) - new Date(b.conf_event.conf_event_date_local))
                    .map((item, index) => {
                        return <ConfEventItem confEvent={item.conf_event} key={index} />
                    })} */}

                {confEventList && confEventList
                    .filter(confEvent => ["78", "95"].includes(confEvent.type))
                    //dedup by conf_event_date
                    .filter((confEvent, index, self) =>
                        index === self.findIndex((t) => (
                            t.conf_event_date === confEvent.conf_event_date
                        ))
                    )
                    .map((item, index) => {
                        return <div>

                            <div className="bg-neutral-800 text-white p-3 font-bold text-xl"><Moment format="LL">{item.conf_event_date_local}</Moment></div>
                            {confEventList && confEventList

                                .filter(confEvent => confEvent.conf_event_date === item.conf_event_date)
                                .filter(confEvent => ["78", "95"].includes(confEvent.type))
                                .map((item, index) => {
                                    return <ConfEventItem
                                        contactConferencierListIsLoading={contactConferencierListIsLoading}
                                        updateIsLoading={updateIsLoading}
                                        contactConferencierList={contactConferencierList}
                                        handleChange={handleChange}
                                        confEvent={item} key={index} />
                                })}


                        </div>
                    })}



            </div>

            {/* {JSON.stringify(contactConferencierList)}
 */}

        </LayoutAccountEvent>
    )
}

export default MyAgenda