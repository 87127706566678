import React, { useContext, useEffect } from 'react'
import Layout from './Layout'
import { useState } from 'react';
import UserContext from '../contexts/UserContext'
import { Link, useHistory } from 'react-router-dom';
import EventContext from '../contexts/EventContext';
import { sortByAttribute } from '../utils';
import { API_URL } from '../config';

const Login = () => {

    const history = useHistory();

    const [login, setLogin] = useState("");

    const [password, setPassword] = useState("");

    const [message, setMessage] = useState("")

    const userContext = useContext(UserContext);

    const { handleLogin, user, userRoleList, userInit, getUserEventsList } = userContext;

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const handleCreateConferencier = async (id_contact) => {

        await fetch(`${API_URL}?action=createConferencier`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id_contact: id_contact,
                id_event: 1927,
                statut: "234"
            })
        })
            .then(res => { getUserEventsList(id_contact); return res })
            .then(res => {
                history.push(`/account/event/1927/techxploration`);

                return false
            })
    }

    useEffect(() => {

        if (user) {

            userRoleList && console.log('userRoleList', userRoleList)

            if (userRoleList && userRoleList?.length > 0) {

                let newNextEvent = userRoleList
                    .map(userContactConferencier => userContactConferencier.event)

                newNextEvent = sortByAttribute(newNextEvent, 'event_start').reverse()[0]

                console.log(newNextEvent)

                history.push(`/account/event/${newNextEvent.id_event}`);
                console.log('event  userRoleList', userRoleList)

            } else if (!userRoleList) {

                console.log('no roles')
                handleCreateConferencier(user.id_contact)

            } else {
                console.log('profil userRoleList', userRoleList)

                history.push('/account/profil/');
            }
        }

    }, [user, userRoleList])


    return (

        <Layout className="bg-zinc-50 ">

            <div className=" mx-auto max-w-xl">

                {message}
                <div className="card lg:p-5 border-0 rounded-3xl">
                    <div className="card-body">
                        <h2 className="card-title text-2xl font-bold pb-3">Connection</h2>
                        <h1 className="pb-4 text-lg text-gray-600">{currentEvent?.nom}</h1>

                        <p className="card-text">
                            <form onSubmit={(e) => handleLogin({ e, login, password, setMessage })}>
                                <div className="form-group">
                                    <label className="mb-2 block text-sm font-semibold text-gray-900" htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control mb-3  form-control-lg"
                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"

                                        onChange={(e) => {
                                            setLogin(e.target.value)
                                        }}
                                    />
                                    <small id="emailHelp" className="form-text text-muted"></small>
                                </div>
                                <div className="form-group">
                                    <label className="mb-2 block text-sm font-semibold text-gray-900" htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control  form-control-lg"
                                        id="exampleInputPassword1" placeholder="Password"

                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                    />
                                </div>

                                <div className="flex justify-end py-2">

                                    <div className="form-group form-check  d-none">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                                    </div>

                                    <Link className="font-medium text-indigo-600 hover:text-indigo-500" to="/password-recovery">
                                        Mot de passe oublié ?
                                    </Link>
                                </div>
                                <br />
                                <button type="submit" className="btn btn-primary w-full p-3">Envoyer</button>
                            </form>
                        </p>
                    </div>
                </div>

            </div>


        </Layout >
    )
}

export default Login