import { useEffect, useState } from "react";
import useApiUrlTranslation from "../components/useApiUrlTranslation";

const useGetEventContactTypeList = ({ filter = null }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeList, setEventContactTypeList] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=${filter}`)
            .then(res => res.json())
            .then(res => setEventContactTypeList(res))
            .then(() => setIsLoading(false))
    }

    useEffect(() => {
        getEventContactTypeList().then(() => {
            setIsLoading(false);
        });

    }, [i18n.resolvedLanguage])

    return { eventContactTypeList, isLoading }

}

export default useGetEventContactTypeList;