import React, { useState, useCallback, useEffect } from 'react';
import RegistrationFormSection from './RegistrationFormSection';
import { ProgressBarCircle } from './ProgressBars';
import { ArrowDownLeftIcon, ArrowLeftIcon, PhotoIcon, VideoCameraIcon } from '@heroicons/react/20/solid';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';
import useApiUrlTranslation from './useApiUrlTranslation';
import { use } from 'react';
import { API_URL } from '../config';
import PrestaIllustrationSection from './PrestaIllustrationSection';


const Visualisation = ({ contribution }) => {
    if (!contribution || !contribution.file_src) {
        return <span className="text-gray-700">Pas d'illustration</span>;
    }

    const fileSrc = contribution.file_src;

    // Vérifier que fileSrc est bien une chaîne
    if (typeof fileSrc !== 'string') {
        return <span className="text-gray-700">Visuel non supporté</span>;
    }

    // Vérifier les formats
    const isVideo = fileSrc.includes('.mp4');
    const isImage = fileSrc.includes('.png') || fileSrc.includes('.jpg');

    return (
        <div className="flex items-center space-x-2">
            {isVideo && (
                <video className="w-24 h-24" controls>
                    <source src={fileSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}

            {isImage && (
                <img className="w-10 h-10" src={fileSrc} alt="illustration" />
            )}

            <a
                rel='noreferrer noopener'
                target='_blank'
                className="flex w-fit px-2 py-1 text-sm border bg-gray-500 text-white rounded font-normal items-center"
                href={fileSrc}
            >
                Visualiser
            </a>
        </div>
    );
};



const RegistrationFormContribution = ({ activeContribution,
    handleAddContribution, handleInputChange, contributionList }) => {


    const createButtonRef = React.useRef(null);



    return (
        <div className=" flex flex-col space-y-3 border p-4 rounded-md shadow-sm">



            <label className="block">
                <span className="text-gray-700"> Nom de la sous partie (50 caractères max) <span className="text-red-700">*</span> :</span>
                <input
                    className="mt-1 block w-full rounded-full p-2 border  border-gray-700  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    type="text"
                    name="name"
                    maxLength={50}
                    placeholder="e.g., AI, Healthcare"
                    onChange={handleInputChange}
                />
            </label>
            <label className="block hidden">
                <span className="text-gray-700">Type:</span>
                <select
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    type="text"
                    name="type"
                    placeholder="e.g., Online, In-person"
                    onChange={handleInputChange}
                >
                    <option value="Online">Sous thématique de formation</option>
                </select>
            </label>

            <span className="text-gray-700 font-bold">Chargement de l'illustration (visuel ou vidéo):</span>

            <PrestaIllustrationSection handleInputChange={handleInputChange} />

            <label className="block">
                <span className="text-gray-700">Descriptif de la sous-partie(200 caractères max):</span>
                <textarea
                    className="mt-1 block w-full rounded-md border-gray-700 border p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    name="description"
                    placeholder="Descriptif de la sous-partie"
                    onChange={handleInputChange}
                    maxLength={200}
                    rows="3"
                />
            </label>
            <button
                ref={createButtonRef}
                onClick={handleAddContribution}
                type="submit"
                disabled={activeContribution.name == "" ? 'hidden' : ''}
                className={`w-fit bg-blue-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            >
                Créer les sous partie (Max 5 : {5 - contributionList.length} left)
            </button>


        </div>
    );
};

const RegistrationFormContributionsSection = ({
    instructions,
    title,
    subtitle,
    confEvent,
    contact
}) => {
    const [contributionList, setContributionList] = useState([]);
    const [activeContribution, setActiveContribution] = useState({ id_contact: contact.id_contact, id_conf_event: confEvent.id_conf_event });

    const { apiUrlTranslation } = useApiUrlTranslation();


    const createConfEventContribution = async () => {

        await (fetch(`${API_URL}?action=createConfEventContribution`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(activeContribution)
        }))
            .then(res => res.json())
            .then(res => {

                updateConfEventContributionFile({ id_conf_event_contribution: res });
                if (res.success) {
                    //setContributionList([...contributionList, activeContribution]);

                    getConfEventContribution();
                    setActiveContribution({ id_conf_event: confEvent.id_conf_event, id_contact: contact.id_contact });
                }
            })
    }


    const handleAddContribution = (e) => {
        e.preventDefault();
        setContributionList([...contributionList, activeContribution]);

        createConfEventContribution();
        setActiveContribution({ id_conf_event: confEvent.id_conf_event, id_contact: contact.id_contact });
    };

    const updateConfEventContributionFile = async ({ id_conf_event_contribution }) => {

        let formData = new FormData();
        formData.append('file_src', activeContribution.file_src, activeContribution.file_src.name);
        formData.append('id_conf_event_contribution', id_conf_event_contribution);
        formData.append('id_contact', contact.id_contact);

        await (fetch(`${API_URL}?action=updateConfEventContribution&id_conf_event_contribution=${id_conf_event_contribution}`, {
            method: 'POST',
            body: formData,

        }))
            .then(res => res.text())
            .then(res => {
                getConfEventContribution();
                setActiveContribution({ id_conf_event: confEvent.id_conf_event, id_contact: contact.id_contact });

            })
    }

    const handleInputChange = (e) => {


        if (e.target.type === 'file') {
            setActiveContribution({ ...activeContribution, [e.target.name]: e.target.files[0] });
            return;
        } else {

            setActiveContribution({ ...activeContribution, [e.target.name]: e.target.value });
        }
    };


    const getConfEventContribution = async () => {

        await fetch(`${apiUrlTranslation}?action=getConfEventContribution&filter= WHERE id_conf_event IN(${confEvent.id_conf_event})`)
            .then(res => res.json())
            .then(res => setContributionList(res))
    }

    const getSteps = () => {
        const steps = contributionList.map((contribution, index) => ({
            name: `Contribution ${index + 1}`,
            status: 'complete'
        }));

        if (contributionList.length < 5) {
            steps.push({
                name: `Contribution ${contributionList.length + 1}`,
                status: steps.length === 0 ? 'current' : 'upcoming'
            });
        }

        while (steps.length < 5) {
            steps.push({
                name: `Contribution ${steps.length + 1}`,
                status: 'upcoming'
            });
        }

        return steps;
    }


    const deleteConfEventContribution = async (id_conf_event_contribution) => {

        await fetch(`${apiUrlTranslation}?action=deleteConfEventContribution&id_conf_event_contribution=${id_conf_event_contribution}`)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    getConfEventContribution();
                }
            })

    }
    const handleDeleteContribution = (e) => {
        e.preventDefault();
        const index = e.currentTarget.dataset.index;

        const id_conf_event_contribution = e.currentTarget.getAttribute('id_conf_event_contribution');

        const newContributionList = contributionList.filter((_, i) => i !== parseInt(index));
        setContributionList(newContributionList);

        deleteConfEventContribution(id_conf_event_contribution)
    };

    const handleEditContribution = (event, contribution) => {
        event.preventDefault();
        // const index = e.currentTarget.dataset.index;
        // const contribution = contributionList[index];
        setActiveContribution(contribution);
    };

    useEffect(() => {
        getConfEventContribution();
    }, []);



    return (
        <RegistrationFormSection
            formSectionId={43}
            title={title}
            instructions={instructions}
        >

            <ProgressBarCircle steps={getSteps()} />



            {subtitle && <p className="text-lg font-lg my-3"> Ajouter les sous-parties et les visuels de ma formation :  <span className="font-bold">{subtitle}</span></p>}

            <div className="flex space-x-2 mt-2 flex-col lg:flex-row ">
                <div className="lg:basis-4/12">
                    {contributionList.length < 5 ? <RegistrationFormContribution
                        handleInputChange={handleInputChange}
                        handleAddContribution={handleAddContribution}
                        activeContribution={activeContribution}
                        contributionList={contributionList}
                    /> : <div className="bg-yellow-100 p-4 rounded-md">Vous avez atteint le nombre maximum de sous-parties

                        <p></p></div>}
                </div>
                <div className="lg:basis-8/12">
                    <div className="overflow-x-auto ">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Index
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Titre
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Déscription

                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        visualiser
                                    </th>


                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {contributionList.map((contribution, index) => (
                                    <tr key={index} className="divide-x divide-gray-200">
                                        <td className="px-6 py-1 whitespace-nowrap">{index + 1}</td>
                                        <td className="px-6 py-1 whitespace-nowrap">{contribution.name}</td>
                                        <td className="px-6 py-1 whitespace-nowrap">{contribution.type}</td>
                                        <td className="px-6 py-1 whitespace-nowrap">


                                            <Visualisation contribution={contribution} />

                                        </td>

                                        <td className="px-6 py-1">{contribution.text}</td>

                                        <td className="px-6 py-1 whitespace-nowrap text-right text-sm font-medium">
                                            <div className="flex space-x-2">
                                                <button
                                                    className="hidden w-fit px-2 text-sm border bg-gray-500 text-white rounded font-normal"
                                                    onClick={(e) => handleEditContribution(e, contribution)}
                                                    data-index={index}
                                                >Edit</button>

                                                <button
                                                    className="w-fit px-2 py-1 text-sm border bg-gray-500 text-white rounded font-normal"
                                                    onClick={handleDeleteContribution}
                                                    id_conf_event_contribution={contribution.id_conf_event_contribution}
                                                    data-index={index}
                                                >Delete</button>






                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </RegistrationFormSection>
    );
};

export default RegistrationFormContributionsSection;