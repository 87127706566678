import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const capitalize = s => s[0] ? s[0].toUpperCase() + s.slice(1) : '';

export const removeTags = (str) => str.replace(/(<([^>]+)>)/ig, '');

export const arrayFiltered = (arg, arr) => {

    return arr.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t[arg] === item[arg]
        ))
    )
}


// Utility function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};


export const sortByAttribute = (array, ...attrs) => {
    // generate an array of predicate-objects contains
    // property getter, and descending indicator
    let predicates = attrs.map(pred => {
        let descending = pred.charAt(0) === '-' ? -1 : 1;
        pred = pred.replace(/^-/, '');
        return {
            getter: o => o[pred],
            descend: descending
        };
    });
    // schwartzian transform idiom implementation. aka: "decorate-sort-undecorate"
    return array.map(item => {
        return {
            src: item,
            compareValues: predicates.map(predicate => predicate.getter(item))
        };
    })
        .sort((o1, o2) => {
            let i = -1, result = 0;
            while (++i < predicates.length) {
                if (o1.compareValues[i] < o2.compareValues[i]) result = -1;
                if (o1.compareValues[i] > o2.compareValues[i]) result = 1;
                if (result *= predicates[i].descend) break;
            }
            return result;
        })
        .map(item => item.src);
}

export const removeDuplicateObjectFromArray = (array, key) => {
    var check = new Set();
    return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
}

export const removeDuplicateObjectsByProperty = (arr, property) => {
    return arr.filter(function (item, index) {
        return index === arr.findIndex(obj => obj[property] === item[property]);
    });
}

export const isDev = () => {

    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

}

export const arrayIntersection = (arr1, arr2) => {
    const set = new Set(arr2);
    const intersection = new Set(arr1.filter(elem => set.has(elem)));
    return Array.from(intersection);
}
export const mobileCheck = () => {
    if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
    } else {
        return false;
    }
};



export const hidePartOfString = (str, start, end) => {
    let hiddenStr = "";
    for (let i = 0; i < str.length; i++) {
        if (i >= start && i < end) {
            hiddenStr += "*";
        } else {
            hiddenStr += str[i];
        }
    }
    return hiddenStr;
};

export const dataUrlToFile = (dataUrl, fileName) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
}

export const CheckData = ({ value, name, content, setShowEditSection, className, valueClassName }) => {

    const { t } = useTranslation();

    useEffect(() => {
        // console.log(name, value)
    }, [value])

    const val = ["", null, false, " ", undefined, "undefined", '[]', []].includes(value) ? <a className='text-red-600 mr-2 cursor-pointer' onClick={() => setShowEditSection && setShowEditSection(true)}  >{t("global.ajouter")} </a> : value;

    return <div className="flex flex-row justify-center items-center">
        <div className="pr-2   basis-2/6">
            <div className="text-right">
                {capitalizeFirstLetter(name)} <span>:</span>
            </div>
        </div>
        <div className="basis-4/6 p-1">
            <div className="flex items-center">
                <div className={valueClassName}>
                    {val} {content}
                </div>
            </div>
        </div>
    </div>
}