import React, { useEffect, useState } from 'react'
import RegistrationFormContributionsSection from './RegistrationFormContributionsSection'

import useApiUrlTranslation from './useApiUrlTranslation';
import { use } from 'react';


const RegistrationFormContributionsAllSection = ({ contact }) => {

    const urlParams = new URLSearchParams(window.location.search);
    const id_conf_event = urlParams.get('id_conf_event_list');

    const [confEventList, setConfEventList] = useState([]);
    const { apiUrlTranslation } = useApiUrlTranslation();


    const getConfEvent = async () => {

        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter= id_conf_event IN(${id_conf_event})`)
            .then(res => res.json())
            .then(res => setConfEventList(res))
    }

    useEffect(() => {

        getConfEvent();

    }, [contact])

    if (!contact || !confEventList) {
        return null;
    }

    return (
        <>
            {confEventList.map((confEvent, index) => {

                return (
                    <RegistrationFormContributionsSection
                        key={index}
                        contact={contact}
                        confEvent={confEvent}
                        title="Chargement des sous parties de ma formation en ligne"
                        subtitle={confEvent.conf_event_lang.cel_titre}
                        instructions="Veuillez charger ci-dessous les sous-thématiques de votre formation, accompagnées d’un visuel d’illustration et d’un texte descriptif de 200 caractères maximum." />
                )
            }
            )}
        </>
    )
}

export default RegistrationFormContributionsAllSection