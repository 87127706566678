import React, { useRef, useState, useEffect, useContext } from 'react'
import { ArrowDownLeftIcon, ArrowLeftCircleIcon, ArrowLeftIcon, ArrowRightCircleIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import { API_URL } from '../../config';

import jwt_decode from "jwt-decode";
import { hidePartOfString } from '../../utils';
import useApiUrlTranslation from '../useApiUrlTranslation';
import EventContext from '../../contexts/EventContext';
import UserContext from '../../contexts/UserContext';

const LoginCheckUser = ({ query, redirectUrl, setIsUserExistAction }) => {

    const [code, setCode] = useState(["", "", "", ""]);
    const inputs = useRef([...Array(4)].map(() => React.createRef()));
    const [formIsSubmited, setFormIsSubmited] = useState(false);
    const [activationCode, setActivationCode] = useState(null);
    const [message, setMessage] = useState(null);
    const { t, apiUrlTranslation, i18n } = useApiUrlTranslation()

    const { getEventByIdEvent, currentEvent } = useContext(EventContext);

    const [login, setLogin] = useState(null);
    const [password, setPassword] = useState(null);
    const [id_contact, setIdContact] = useState(null);
    const [id_contact_encrypted, setIdContactEncrypted] = useState(null);

    const userContext = useContext(UserContext);

    const { handleLogin, user, userRoleList, userInit, getUserEventsList } = userContext;



    //const { redirectUrl } = useParams();

    const handleUpdateLogin = (e) => {

        e.preventDefault();

        setLogin(e.target.value);
    }

    const handleCreateConferencier = async (id_contact) => {

        await fetch(`${API_URL}?action=createConferencier`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id_contact: id_contact,
                id_event: 1927,
                statut: "234"
            })
        })
            .then(res => {

                window.location.href = `/account/event/1927/techxploration`;

                return false
            })
    }


    const handleChange = (index, value) => {

        const newCode = [...code];

        if (value.length === 4) {
            setCode(Array(4).fill(value));
        } else {

            newCode[index] = value;
            setCode(newCode);
        }

        if (value.length === 0 && inputs.current[index - 1]) {
            inputs.current[index - 1].current.focus();
        }

        if (value.length > 0 && inputs.current[index + 1]) {
            inputs.current[index + 1].current.focus();
        }

        if ((newCode.length === 4) && (newCode.join('').includes(activationCode) || value.substring(0, 4) == activationCode)) {

            if (redirectUrl) {

                let url = '';

                // url = `https://www.event2one.com/vote/#/ije/apllZA==/${id_contact}`;

                //url = `${decodeURIComponent(redirectUrl)}${id_contact}`;
                //url = `${query.get("redirectUrl")}/${id_contact}`;
                url = redirectUrl
                    .replace('{id_contact}', id_contact)
                    .replace('%7bid_contact%7d', id_contact)
                    .replace('%7Bid_contact%7D', id_contact)
                    .replace('{id_contact_encrypted}', id_contact_encrypted);

                //si url contient techxploration handleCreateConferencier sinon redirection

                if (url.includes('techxploration')) {
                    handleLogin({ e: { preventDefault: () => { } }, login: login, password, setMessage });

                    handleCreateConferencier(id_contact);
                    return false;
                } else {

                    window.location.href = url;
                }

                //setIsUserExistAction(true);
                //setRedirectUrl();
                console.log('OK REDIREDCT')
                setMessage(<div className="alert alert-success">Connexion... {url}</div>);
            } else {

                setIsUserExistAction(true);

                // Obtenir l'URL courante
                const currentUrl = window.location.href;

                // Ajouter "/c/607" à l'URL
                const modifiedUrl = `${currentUrl}c/${id_contact}?formisvisible=1`

                // Rediriger vers la nouvelle URL
                window.location.href = modifiedUrl;
            }

        } else if ((newCode.length === 4) || (!newCode.join('').includes(activationCode) && value.substring(0, 4) != activationCode)) {
            setMessage(<div className="alert alert-danger">Code éroné</div>);
        } else {
            setMessage(null);
        }
    }

    const sendCodeToUser = async () => {
        try {
            await fetch(`${API_URL}?action=sendCodeToUser`, {
                method: 'POST',
                body: JSON.stringify({
                    login: login,
                    id_event: currentEvent?.id_event,
                }),
            }
            )
                .then(res => res.json())
                .then(response => {

                    const token = jwt_decode(response)

                    //console.log(token)
                    try {
                        // console.log(response)
                        // console.log('token', token)
                        if (token.data.accessToken === 1) {
                            setFormIsSubmited(true);
                            setActivationCode(token.data.activation_code);
                            setIdContact(token.data.id_contact);
                            setIdContactEncrypted(token.data.id_contact_encrypted);
                            setLogin(token.data.login);
                            setPassword(token.data.pass);
                        } else {
                            alert('Une erreur est survenue')
                        }

                    } catch (err) {
                        alert(err.message);
                    }
                })
        } catch (err) {
            console.log(err, 'errr')
        }
    }

    return (
        <>
            {!formIsSubmited && <div className='flex flex-col mx-auto w-96'>
                {message}
                <div className='text-white text-xs'>{redirectUrl}</div>
                <h1 className='my-3 text-2xl d-none'>{t('sections.check_user.indiquer_email')}</h1>
                <input
                    type="text"
                    name='login'
                    id="login"
                    className='bg-gray-100 p-3 rounded text-lg my-3'
                    placeholder_="Saisissez un numéro de téléphone ou une adresse e-mail"
                    placeholder={t('sections.check_user.indiquer_email')}
                    onChange={handleUpdateLogin} />
                <button
                    className='bg-black/95 hover:bg-black/80 text-white p-3 rounded-lg text-lg 
                transition duration-500'
                    onClick={sendCodeToUser}>{
                        'Log in '
                        //t('sections.check_user.continuer')
                    }</button>
                <div className='hidden'> {t('sections.check_user.mention_legal')}</div>
            </div>
            }

            {formIsSubmited && <div className='flex flex-col mx-auto w-96'>
                {message}
                <h1 className='my-3 text-lg text-center'> {t('sections.check_user.saisir_code')} {hidePartOfString(login, 4, 12)}</h1>
                <div className='flex justify-between'>
                    {code.map((digit, index) => (
                        <input
                            ref={inputs.current[index]}
                            className='bg-gray-200 p-3 rounded text-lg my-3 w-20 text-center userCheckerInput'
                            key={index}
                            type="number"
                            maxLength={4}
                            value={digit[index]}
                            onChange={(e) => handleChange(index, e.target.value)}

                        />
                    ))}
                </div>
                <button className='bg-gray-100 hover:bg-gray-200 p-3 rounded-full transition duration-500 text-gray-900 hidden'>{t('sections.check_user.code_non_recu')}</button>

                <div className='flex justify-between my-5'>
                    <button
                        onClick={() => setFormIsSubmited(false)}
                        className='bg-gray-100 hover:bg-gray-300 text-gray-900 p-3 rounded-full text-lg font-bold transition duration-500'>
                        <ArrowLeftIcon className='text-gray-900 w-6 ' />
                    </button>
                    <button className='flex justify-between bg-gray-100 hover:bg-black/80 hover:text-gray-400  text-gray-500 p-3 rounded-full text-lg transition duration-500'>
                        Suivant <ArrowRightIcon className='text-gray-500  w-6 text-xl' />
                    </button>
                </div>

            </div>
            }

        </>
    )
}

export default LoginCheckUser